import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Component Component" description="A dynamic, functional version of a React.Component; a component component if you will." mdxType="SEO" />
    <h1 {...{
      "id": "component-component"
    }}>{`Component Component`}</h1>
    <blockquote>
      <p parentName="blockquote">{`NOTE: This component was developed in the days before React Hooks.
In most cases, you should probably build your function components using hooks and use a class
component in the rare cases you need them. We may deprecate this component in the future.`}</p>
    </blockquote>
    <p>{`A dynamic, functional version of `}<inlineCode parentName="p">{`React.Component`}</inlineCode>{`, a component component if you will. Useful for inline lifecycles and state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Component initialState={{ hue: 0 }}>
  {({ setState, state }) => (
    <div style={{ textAlign: "center" }}>
      <button onClick={() => setState({ hue: Math.random() * 360 })}>
        Generate Triad Colorscheme
      </button>
      <br />
      {[1, 2, 3].map((n) => (
        <div
          key={n}
          style={{
            display: "inline-block",
            margin: 10,
            width: "2em",
            height: "2em",
            borderRadius: "50%",
            background: \`hsl(\${state.hue + n * 120}, 50%, 50%)\`,
            transition: "background-color 200ms ease",
          }}
        />
      ))}
    </div>
  )}
</Component>
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/component-component`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/component-component`}</inlineCode>{`. Then import the component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/component-component
# or
yarn add @reach/component-component
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Component from "@reach/component-component";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#initialstate"
            }}><inlineCode parentName="a">{`initialState`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`object`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#getinitialstate"
            }}><inlineCode parentName="a">{`getInitialState`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#refs"
            }}><inlineCode parentName="a">{`refs`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`object`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#getrefs"
            }}><inlineCode parentName="a">{`getRefs`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#didmount"
            }}><inlineCode parentName="a">{`didMount`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#didupdate"
            }}><inlineCode parentName="a">{`didUpdate`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "willunmount"
            }}><inlineCode parentName="a">{`willUnmount`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#getsnapshotbeforeupdate"
            }}><inlineCode parentName="a">{`getSnapshotBeforeUpdate`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#shouldupdate"
            }}><inlineCode parentName="a">{`shouldUpdate`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#render"
            }}><inlineCode parentName="a">{`render`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "initialstate"
    }}>{`initialState`}</h4>
    <p><inlineCode parentName="p">{`initialState?: object`}</inlineCode></p>
    <p>{`An object of initial state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Component initialState={{ count: 10 }}>
      {({ state }) => <div>Count is {state.count}</div>}
    </Component>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "getinitialstate"
    }}>{`getInitialState`}</h4>
    <p><inlineCode parentName="p">{`getInitialState?: () => object`}</inlineCode></p>
    <p>{`A function to return intitial state. Use this when initial state is computed.`}</p>
    <p>{`In the following example, `}<inlineCode parentName="p">{`Date.now()`}</inlineCode>{` will `}<em parentName="p">{`not`}</em>{` be called every time a parent component causes this component to re-render.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Component getInitialState={() => ({ now: Date.now() })}>
  {({ state }) => <div>Now is: {state.now}</div>}
</Component>
`}</code></pre>
    <p>{`However, in the next example, `}<inlineCode parentName="p">{`Date.now()`}</inlineCode>{` would be called with every re-render, which is not what we want.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// 😭
<Component initialState={{ now: Date.now() }} />
`}</code></pre>
    <h4 {...{
      "id": "refs"
    }}>{`refs`}</h4>
    <p><inlineCode parentName="p">{`refs?: object`}</inlineCode></p>
    <p>{`Put any refs you need to keep track of here, stuff like DOM nodes, timers, and subcriptions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Component refs={{ input: null }}>
      {({ refs }) => (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            alert(refs.input.value);
          }}
        >
          <input ref={(node) => (refs.input = node)} type="text" />{" "}
          <button type="submit">Go</button>
        </form>
      )}
    </Component>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "getrefs"
    }}>{`getRefs`}</h4>
    <p><inlineCode parentName="p">{`getRefs?: () => object`}</inlineCode></p>
    <p>{`Use this when any of your refs are computed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Component
  getRefs={() => {
    return {
      input: React.createRef(),
      popupContainer: document.createElement("div"),
    };
  }}
/>
`}</code></pre>
    <h4 {...{
      "id": "didmount"
    }}>{`didMount`}</h4>
    <p><inlineCode parentName="p">{`didMount?: (args: { state: object, props: object, refs: object, setState: Function, forceUpdate: Function }) => void`}</inlineCode></p>
    <p>{`Called when the component mounts.`}</p>
    <p>{`Perhaps you want some async data but don't want to make an entirely new component just for the lifecycles to get it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Component
  initialState={{ gists: null }}
  didMount={({ setState }) => {
    fetch("https://api.github.com/gists?per_page=5")
      .then((res) => res.json())
      .then((gists) => setState({ gists }));
  }}
>
  {({ state }) =>
    state.gists ? (
      <ul>
        {state.gists.map((gist) => (
          <li key={gist.id}>
            <a href={gist.html_url}>{gist.description || gist.id}</a>
          </li>
        ))}
      </ul>
    ) : (
      <div>Loading...</div>
    )
  }
</Component>
`}</code></pre>
    <p>{`See also `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/react-component.html#componentdidmount"
      }}>{`React Docs`}</a>{`.`}</p>
    <h4 {...{
      "id": "didupdate"
    }}>{`didUpdate`}</h4>
    <p><inlineCode parentName="p">{`didMount?: (args: { state: object, props: object, refs: object, setState: Function, forceUpdate: Function, prevProps?: object, prevState?: object }) => void`}</inlineCode></p>
    <p>{`Called when the component updates. See `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/react-component.html#componentdidupdate"
      }}>{`React Docs`}</a>{`.`}</p>
    <h4 {...{
      "id": "willunmount"
    }}>{`willUnmount`}</h4>
    <p><inlineCode parentName="p">{`willUnmount?: (args: { state: object, props: object, refs: object }) => void`}</inlineCode></p>
    <p>{`Called when the component will be removed from the page. See `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/react-component.html#componentwillunmount"
      }}>{`React Docs`}</a>{`.`}</p>
    <h4 {...{
      "id": "getsnapshotbeforeupdate"
    }}>{`getSnapshotBeforeUpdate`}</h4>
    <p><inlineCode parentName="p">{`getSnapshotBeforeUpdate?: (args: { state: object, props: object, refs: object, prevProps: object, prevState: object }) => any`}</inlineCode></p>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/react-component.html#getsnapshotbeforeupdate"
      }}>{`React Docs`}</a>{`.`}</p>
    <h3 {...{
      "id": "shouldupdate"
    }}>{`shouldUpdate`}</h3>
    <p><inlineCode parentName="p">{`shouldUpdate?: (args: { state: object, props: object, nextProps: object, nextState: object }) => boolean`}</inlineCode></p>
    <p>{`Return `}<inlineCode parentName="p">{`true`}</inlineCode>{` to signify the component needs an update, `}<inlineCode parentName="p">{`false`}</inlineCode>{` if it does not. Useful for wrapping up expensive parts of your app without having to refactor to new components.`}</p>
    <p>{`For example: often you find just one part of your component is expensive to render, maybe because of a large SVG with a dynamic style or two. Rather than pulling the elements out of your component and putting them in a new `}<inlineCode parentName="p">{`PureComponent`}</inlineCode>{`, you can inline a `}<inlineCode parentName="p">{`shoudlUpdate`}</inlineCode>{` check:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Component initialState={{ hue: 0 }}>
      {({ setState, state }) => (
        <div>
          <button
            onClick={() => {
              setState({ hue: Math.random() * 360 });
            }}
          >
            Change Color
          </button>

          <Component
            hue={state.hue}
            shouldUpdate={({ nextProps, props }) => {
              return nextProps.hue !== props.hue;
            }}
          >
            <div>
              <svg width="100" height="100">
                <path
                  d="M20,30 Q40,5 50,30 T90,30"
                  fill="none"
                  stroke={\`hsl(\${state.hue}, 50%, 50%)\`}
                  strokeWidth="5"
                />
              </svg>
            </div>
          </Component>
        </div>
      )}
    </Component>
  );
}
`}</code></pre>
    <p>{`See also `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/react-component.html#shouldcomponentupdate"
      }}>{`React Docs`}</a>{`.`}</p>
    <h4 {...{
      "id": "children"
    }}>{`children`}</h4>
    <p><inlineCode parentName="p">{`children?: React.ReactNode | ((args: { state: object, props: object, refs: object, setState: Function, forceUpdate: Function }) => React.ReactNode)`}</inlineCode></p>
    <p>{`Usual React children prop or render prop callback to provide the stateful parts of your component at render time..`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Component>
      <div>Hey, I am the child</div>
    </Component>
  );
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Component initialState={{ hue: 0 }}>
      {({ setState, state }) => (
        <div>
          <button onClick={() => setState({ hue: Math.random() * 360 })}>
            Generate Color
          </button>
          <br />
          <svg width="100" height="100">
            <path
              d="M20,30 Q40,5 50,30 T90,30"
              fill="none"
              stroke={\`hsl(\${state.hue}, 50%, 50%)\`}
              strokeWidth="5"
            />
          </svg>
        </div>
      )}
    </Component>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "render"
    }}>{`render`}</h4>
    <p><inlineCode parentName="p">{`render?: (args: { state: object, props: object, refs: object, setState: Function, forceUpdate: Function }) => React.ReactNode`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Component
      initialState={{ hue: 0 }}
      render={({ setState, state }) => (
        <div>
          <button onClick={() => setState({ hue: Math.random() * 360 })}>
            Generate Color
          </button>
          <br />
          <svg width="100" height="100">
            <path
              d="M20,30 Q40,5 50,30 T90,30"
              fill="none"
              stroke={\`hsl(\${state.hue}, 50%, 50%)\`}
              strokeWidth="5"
            />
          </svg>
        </div>
      )}
    />
  );
}
`}</code></pre>
    <h2 {...{
      "id": "todo-app-example"
    }}>{`Todo App Example`}</h2>
    <p>{`Here is a pretty involved example showing just how composable this component is:`}</p>
    <ul>
      <li parentName="ul">{`"App state" containing all todos`}</li>
      <li parentName="ul">{`"Todo state" containing state for a specific todo`}</li>
      <li parentName="ul">{`Updates the document title to the number of todos in the list`}</li>
      <li parentName="ul">{`Optimized todo rendering, avoiding updates if the color has not changed`}</li>
      <li parentName="ul">{`Tracked refs`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Component
      getRefs={() => ({
        input: React.createRef(),
      })}
      getInitialState={() => {
        return {
          todos: ["This is kinda weird"],
        };
      }}
    >
      {({ state, setState, refs }) => (
        <>
          <Component
            didUpdate={() => (document.title = state.todos.length + " Todos")}
          />
          <div style={{ fontFamily: "sans-serif" }}>
            <h4>Todo List</h4>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                let node = refs.input.current;
                setState({ todos: state.todos.concat([node.value]) });
                node.value = "";
              }}
            >
              <input ref={refs.input} />
            </form>
            <div>
              {state.todos.map((todo, index) => (
                <Component
                  key={index}
                  getInitialState={() => ({ hue: Math.random() * 360 })}
                  todo={todo}
                  shouldUpdate={({ nextProps, nextState, props, state }) => {
                    return (
                      nextProps.todo !== props.todo ||
                      nextState.hue !== state.hue
                    );
                  }}
                >
                  {({ setState, state }) => (
                    <div style={{ color: \`hsl(\${state.hue}, 50%, 50%)\` }}>
                      <button
                        onClick={() => {
                          setState({ hue: Math.random() * 360 });
                        }}
                      >
                        Change Color
                      </button>{" "}
                      {todo}
                    </div>
                  )}
                </Component>
              ))}
            </div>
            <p>
              <button onClick={() => setState({ todos: [] })}>Clear all</button>
            </p>
          </div>
        </>
      )}
    </Component>
  );
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      